import React from 'react'

const Sanqueries = ({ withColor }) => {
  return withColor ? (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0197 17.5933C21.3833 15.1455 24.4529 14.278 26.8758 15.6555L43.434 25.0696C45.8569 26.4471 46.7157 29.5481 45.3521 31.9958L33.9803 52.4087C32.6167 54.8564 29.5471 55.7239 27.1242 54.3464L10.566 44.9324C8.14311 43.5548 7.28435 40.4539 8.64794 38.0061L20.0197 17.5933Z"
        fill="#EDF8F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.233 34.3528L50.3941 38.3737L53.6413 44.0479C53.955 44.5962 54.6306 44.7722 55.1501 44.4411C55.6696 44.11 55.8364 43.3971 55.5227 42.8489L51.8591 36.4472C51.6709 36.1183 51.771 35.6905 52.0827 35.4919C52.3944 35.2932 52.7997 35.3988 52.988 35.7278L56.6515 42.1294C57.3418 43.3356 56.9748 44.9039 55.8318 45.6324C54.6889 46.3608 53.2027 45.9735 52.5124 44.7673L49.2559 39.0769L47.0859 35.0393L48.233 34.3528Z"
        fill="#14C393"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.7808 33.4164C52.6 31.0521 53.7796 26.0395 51.4154 22.2202C49.0512 18.4009 44.0385 17.2214 40.2192 19.5856C36.4 21.9498 35.2204 26.9625 37.5846 30.7818C39.9488 34.601 44.9615 35.7806 48.7808 33.4164ZM49.4995 34.5775C53.9601 31.8163 55.3377 25.962 52.5765 21.5014C49.8153 17.0409 43.961 15.6633 39.5005 18.4245C35.0399 21.1857 33.6623 27.04 36.4235 31.5005C39.1847 35.961 45.039 37.3386 49.4995 34.5775Z"
        fill="#FD9317"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 20.659C17 20.2956 17.3376 20.001 17.754 20.001H21.246C21.6624 20.001 22 20.2956 22 20.659C22 21.0224 21.6624 21.317 21.246 21.317H17.754C17.3376 21.317 17 21.0224 17 20.659ZM17 45.3426C17 44.9792 17.309 44.6846 17.6903 44.6846H28.1032C28.4845 44.6846 28.7935 44.9792 28.7935 45.3426C28.7935 45.706 28.4845 46.0006 28.1032 46.0006H17.6903C17.309 46.0006 17 45.706 17 45.3426ZM30.0162 45.3426C30.0162 44.9792 30.3253 44.6846 30.7065 44.6846H33.3097C33.691 44.6846 34 44.9792 34 45.3426C34 45.706 33.691 46.0006 33.3097 46.0006H30.7065C30.3253 46.0006 30.0162 45.706 30.0162 45.3426ZM17.754 32.1492C17.3376 32.1492 17 32.4438 17 32.8072C17 33.1706 17.3376 33.4652 17.754 33.4652H21.246C21.6624 33.4652 22 33.1706 22 32.8072C22 32.4438 21.6624 32.1492 21.246 32.1492H17.754Z"
        fill="#14C393"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1349 13.001C14.8978 13.001 13 14.6983 13 16.8922V23.3057C13 24.6424 13.7045 25.7947 14.7611 26.4912C13.7126 27.142 13 28.2195 13 29.5015V35.4874C13 36.7736 13.7172 37.854 14.7713 38.5041C13.7091 39.1998 13 40.3553 13 41.6963V48.1098C13 50.3037 14.8978 52.001 17.1349 52.001H43.8651C46.1022 52.001 48 50.3037 48 48.1098V41.6963C48 40.3561 47.2918 39.2012 46.2306 38.5053C46.3293 38.4441 46.4251 38.379 46.5175 38.3103C46.8213 38.0845 46.8841 37.6556 46.6578 37.3525C46.4315 37.0493 46.0018 36.9866 45.698 37.2124C45.5011 37.3588 45.2776 37.4832 45.0331 37.5791C44.6804 37.7174 44.2852 37.7959 43.8651 37.7959H17.1349C15.5157 37.7959 14.3718 36.6757 14.3718 35.4874V29.5015C14.3718 28.3432 15.4589 27.2495 17.0136 27.1952C17.0783 27.197 17.1442 27.1997 17.2074 27.1931H31.7928C32.1716 27.1931 32.4787 26.8866 32.4787 26.5086C32.4787 26.1306 32.1716 25.8241 31.7928 25.8241H16.9809C15.4853 25.7504 14.3718 24.6094 14.3718 23.3057V16.8922C14.3718 15.544 15.5623 14.37 17.1349 14.37H43.8651C44.8168 14.37 45.6427 14.8076 46.136 15.4557C46.3652 15.7567 46.7955 15.8153 47.0971 15.5866C47.3987 15.3579 47.4575 14.9285 47.2283 14.6275C46.4723 13.6345 45.2403 13.001 43.8651 13.001H17.1349ZM17.1349 39.1741C15.5623 39.1741 14.3718 40.3481 14.3718 41.6963V48.1098C14.3718 49.4579 15.5623 50.632 17.1349 50.632H43.8651C45.4377 50.632 46.6282 49.4579 46.6282 48.1098V41.6963C46.6282 40.3481 45.4377 39.1741 43.8651 39.1741H17.1349Z"
        fill="#375BE9"
      />
    </svg>
  ) : (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0197 17.5933C21.3833 15.1455 24.4529 14.278 26.8758 15.6555L43.434 25.0696C45.8569 26.4471 46.7157 29.5481 45.3521 31.9958L33.9803 52.4087C32.6167 54.8564 29.5471 55.7239 27.1242 54.3464L10.566 44.9324C8.14311 43.5548 7.28435 40.4539 8.64794 38.0061L20.0197 17.5933Z"
        fill="#F7F8FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.233 34.3528L50.3941 38.3737L53.6413 44.0479C53.955 44.5962 54.6306 44.7722 55.1501 44.4411C55.6696 44.11 55.8364 43.3971 55.5227 42.8489L51.8591 36.4472C51.6709 36.1183 51.771 35.6905 52.0827 35.4919C52.3944 35.2932 52.7997 35.3988 52.988 35.7278L56.6515 42.1294C57.3418 43.3356 56.9748 44.9039 55.8318 45.6324C54.6889 46.3608 53.2027 45.9735 52.5124 44.7673L49.2559 39.0769L47.0859 35.0393L48.233 34.3528Z"
        fill="#9FAAC4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.7808 33.4164C52.6 31.0521 53.7796 26.0395 51.4154 22.2202C49.0512 18.4009 44.0385 17.2214 40.2192 19.5856C36.4 21.9498 35.2204 26.9625 37.5846 30.7818C39.9488 34.601 44.9615 35.7806 48.7808 33.4164ZM49.4995 34.5775C53.9601 31.8163 55.3377 25.962 52.5765 21.5014C49.8153 17.0409 43.961 15.6633 39.5005 18.4245C35.0399 21.1857 33.6623 27.04 36.4235 31.5005C39.1847 35.961 45.039 37.3386 49.4995 34.5775Z"
        fill="#505573"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 20.659C17 20.2956 17.3376 20.001 17.754 20.001H21.246C21.6624 20.001 22 20.2956 22 20.659C22 21.0224 21.6624 21.317 21.246 21.317H17.754C17.3376 21.317 17 21.0224 17 20.659ZM17 45.3426C17 44.9792 17.309 44.6846 17.6903 44.6846H28.1032C28.4845 44.6846 28.7935 44.9792 28.7935 45.3426C28.7935 45.706 28.4845 46.0006 28.1032 46.0006H17.6903C17.309 46.0006 17 45.706 17 45.3426ZM30.0162 45.3426C30.0162 44.9792 30.3253 44.6846 30.7065 44.6846H33.3097C33.691 44.6846 34 44.9792 34 45.3426C34 45.706 33.691 46.0006 33.3097 46.0006H30.7065C30.3253 46.0006 30.0162 45.706 30.0162 45.3426ZM17.754 32.1492C17.3376 32.1492 17 32.4438 17 32.8072C17 33.1706 17.3376 33.4652 17.754 33.4652H21.246C21.6624 33.4652 22 33.1706 22 32.8072C22 32.4438 21.6624 32.1492 21.246 32.1492H17.754Z"
        fill="#505573"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1349 13.001C14.8978 13.001 13 14.6983 13 16.8922V23.3057C13 24.6424 13.7045 25.7947 14.7611 26.4912C13.7126 27.142 13 28.2195 13 29.5015V35.4874C13 36.7736 13.7172 37.854 14.7713 38.5041C13.7091 39.1998 13 40.3553 13 41.6963V48.1098C13 50.3037 14.8978 52.001 17.1349 52.001H43.8651C46.1022 52.001 48 50.3037 48 48.1098V41.6963C48 40.3561 47.2918 39.2012 46.2306 38.5053C46.3293 38.4441 46.4251 38.379 46.5175 38.3103C46.8213 38.0845 46.8841 37.6556 46.6578 37.3525C46.4315 37.0493 46.0018 36.9866 45.698 37.2124C45.5011 37.3588 45.2776 37.4832 45.0331 37.5791C44.6804 37.7174 44.2852 37.7959 43.8651 37.7959H17.1349C15.5157 37.7959 14.3718 36.6757 14.3718 35.4874V29.5015C14.3718 28.3432 15.4589 27.2495 17.0136 27.1952C17.0783 27.197 17.1442 27.1997 17.2074 27.1931H31.7928C32.1716 27.1931 32.4787 26.8866 32.4787 26.5086C32.4787 26.1306 32.1716 25.8241 31.7928 25.8241H16.9809C15.4853 25.7504 14.3718 24.6094 14.3718 23.3057V16.8922C14.3718 15.544 15.5623 14.37 17.1349 14.37H43.8651C44.8168 14.37 45.6427 14.8076 46.136 15.4557C46.3652 15.7567 46.7955 15.8153 47.0971 15.5866C47.3987 15.3579 47.4575 14.9285 47.2283 14.6275C46.4723 13.6345 45.2403 13.001 43.8651 13.001H17.1349ZM17.1349 39.1741C15.5623 39.1741 14.3718 40.3481 14.3718 41.6963V48.1098C14.3718 49.4579 15.5623 50.632 17.1349 50.632H43.8651C45.4377 50.632 46.6282 49.4579 46.6282 48.1098V41.6963C46.6282 40.3481 45.4377 39.1741 43.8651 39.1741H17.1349Z"
        fill="#9FAAC4"
      />
    </svg>
  )
}

export default Sanqueries
